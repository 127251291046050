import { BasicsClass } from '@/common/BasicsClass';

export interface merchantForm {
  merchantName: string;
}

export class merchant extends BasicsClass {
  //获取供应商列表
  public getMerchantList(form: merchantForm, page: any, cb: Function) {
    this.BasicGet('/animal/web/ordering/merchant/page', { ...form, ...page }, false, false, cb);
  }
  //获取供应商列表
  public getMerchantListAll(cb: Function) {
    this.BasicGet('/animal/web/ordering/merchant/listAll', { }, false, false, cb);
  }
  //查询供应商信息
  public getMerchantInfo(id: any, cb: Function) {
    this.BasicGet('/animal/web/ordering/merchant/getInfo', { id }, false, false, cb);
  }
  //改变启用状态
  public changestatusBoolean(id: string, status: number,cb:Function): void {
    this.BasicGet('/animal/web/ordering/merchant/updateStatus', {
      id: id,
      status: status
    }, false, false ,cb)
  }
  //新增供应商
  public addMerchant(form: any, cb: any): void {
    this.BasicPost('/animal/web/ordering/merchant/save', {...form}, false, false, true, cb);
  }
  //修改供应商
  public updateMerchant(form: any, cb: any): void {
    this.BasicPost('/animal/web/ordering/merchant/update', {...form}, false, false, true, cb);
  }

}
