
















import { Component, Prop, Watch, Vue } from "vue-property-decorator";
@Component({})
export default class InputConponentsVue extends Vue {
    @Prop() FatherInputVal: any; //父组件绑定的值
    @Prop() LabelText: any;
    @Prop() PlaceholderText: any;
    @Prop() width: any;
    @Prop() IsDisabled: any; //是否禁止选择
    @Prop() EndText: any;
    @Prop() MaxLength: any;
    @Prop() MinLength: any;
    @Prop() IsNumber: any;
    @Prop() positiveInteger: any; //是否是正整数
    @Prop() isChinese: any; //只为汉字
    @Prop() emitCb: any; //emit 回调
    private InputVal: string = ""; //当前组件绑定的值

    private HandlerInput(e: any) {
        //输入框输入时实时将值传给父组件
        this.InputVal = this.checkStr(this.InputVal);
        this.$emit("SetInputVal", this.InputVal);
        if (this.emitCb) {
            this.emitCb(this.InputVal);
        }
    }

    @Watch("FatherInputVal", { immediate: true }) //监视父组件传递过来的值 进行赋值
    private ChangeFatherVal(newVal: any) {
        if (newVal == "") this.InputVal = "";
        if (newVal !== "") this.InputVal = this.checkStr(newVal);
    }

    @Watch("InputVal", { immediate: true }) //监视当前组件绑定的值  传给父组件
    private ChangeInputVal(newVal: any) {
        //检验此版本未声明 未加
        this.$emit("SetInputVal", this.InputVal);
        if (this.emitCb) {
            this.emitCb(this.InputVal);
        }
    }

    private BlurChangeIput(val: any) {
        if (this.isChinese)
            this.InputVal = val.target.value.replace(/[^\u4E00-\u9FA5]/g, "");
    }

    private checkStr(str: any) {
        //校验表情
        let result: any = str;
        // utf8mb4 正则
        const utf8mb4Reg = /[^\u0000-\uFFFF]/g;
        // emoji 表情正则
        const emojiReg = /(\ud83c[\udf00-\udfff])|(\ud83d[\udc00-\ude4f])|(\ud83d[\ude80-\udeff])/g;
        // 包含 utf8mb4 的 4 字节
        const isUTF8MB4 = utf8mb4Reg.test(result);
        // 包含 emoji 表情
        const isEmoj = emojiReg.test(result);
        // 校验特殊字符 并删除
        isUTF8MB4 && (result = result.replace(utf8mb4Reg, ""));
        isEmoj && (result = result.replace(emojiReg, ""));
        if (this.positiveInteger) {
            //校验正整数
            if (result) {
                if (!/^[1-9]+[0-9]*$/.test(result)) {
                    this.$message({
                        showClose: true,
                        message: "请输入正整数",
                        type: "warning",
                    });
                    result = "";
                }
            }
        }
        if (this.IsNumber) {
            if (result) {
                result = result.replace(/[^\d]/g, "");
            }
        }
        //只能输入汉字
        // if(this.isChinese){
        //     if(result){
        //         result = result.replace(/[^\u4E00-\u9FA5]/g , "")
        //     }
        // }
        return result;
    }
}
