

























































































import { Watch, Component, Vue } from "vue-property-decorator";

import InputComonentVue from "@/components/BasicConponents/InputConponentsVue.vue";
import { merchant } from "@/common/Views/BeefOrdering/SupplierManagement/SupplierManagement";

@Component({
    components: {
        InputComonentVue,
    },
})
export default class AddMerchant extends Vue {
    private form: any = {
        merchantName: "", //商户名称
        mchNo: "", //商户号
        secret: "", //secret
        apiKey: "", //key
        contacts: "", //联系人
        mobile: "", //联系电话
    };
    private updateType = false;
    private rules: any = {
        merchantName: [
            { required: true, message: "请设置商户名称", trigger: "change" },
        ],
        mchNo: [{ required: true, message: "请设置商户号", trigger: "change" }],
        secret: [
            { required: true, message: "请设置secret", trigger: "change" },
        ],
        apiKey: [{ required: true, message: "请设置key", trigger: "change" }],
        contacts: [
            { required: true, message: "请输入联系人", trigger: "change" },
        ],
        mobile: [
            { required: true, message: "请输入联系电话", trigger: "change" },
        ],
    };

    private ForageTypeList: any[] = []; //草料类型 数据
    private AdressTreeList: any[] = []; //售卖区域
    private ShelfStatusList: any[] = [
        //上架状态
        { id: "1", name: "上架" },
        { id: "2", name: "下架" },
    ];

    //事件
    private SaveData() {
        //保存
        if (this.form.mobile.length != 11) {
            this.$message({
                type: "warning",
                message: "联系方式格式不对",
            });
            return
        }
        this.$refs.form.validate((flag: boolean) => {
            if (flag) {
                if (this.updateType) {
                    this.updateMerchant(this.form);
                } else {
                    this.addMerchant(this.form);
                }
                setTimeout(() => {
                    this.$router.push({
                        path: "/SupplierManagement",
                    });
                }, 2000);
            }
        });
    }

    //emit事件
    private SetMerchantNameValue(val: string) {
        //商户名称
        this.form.merchantName = val;
    }
    private SetContactsValue(val: string) {
        //联系人
        this.form.contacts = val;
    }
    private SetMobileValue(val: string) {
        //联系方式
        this.form.mobile = val;
    }
    private SetMchIdValue(val: string) {
        //商户号
        this.form.mchNo = val;
    }
    private SetSecretValue(val: string) {
        //secret
        this.form.secret = val;
    }
    private SetKeyValue(val: any[]) {
        //key
        this.form.apiKey = val;
    }
    private addMerchant(form: any) {
        //新增
        new merchant().addMerchant(form, (res: any) => {
            this.$message({
                type: "success",
                message: "新增供应商成功",
            });
        });
    }
    private updateMerchant(form: any) {
        //修改
        new merchant().updateMerchant(form, (res: any) => {
            this.$message({
                type: "success",
                message: "修改供应商成功",
            });
        });
    }
    private ClearFormData() {
        //清楚Form数据
        this.form = {
            merchantName: "", //商户名称
            mchNo: "", //商户号
            secret: "", //secret
            apiKey: "", //key
            contacts: "", //联系人
            mobile: "", //联系电话
        };
    }
    private ClearCheck() {
        //清除校验
        this.$refs.form.clearValidate();
    }

    activated() {
        this.updateType = false;
        const id = this.$route.query.id;
        if (id) {
            new merchant().getMerchantInfo(id, (res: any) => {
                const data = res.data;
                this.SetMerchantNameValue(data.merchantName);
                this.form = {
                    merchantName: data.merchantName,
                    mchNo: data.mchNo,
                    secret: data.secret,
                    apiKey: data.apiKey,
                    id: id,
                    contacts: data.contacts, //联系人
                    mobile: data.mobile, //联系电话
                };
                this.updateType = true;
            });
        } else {
            this.ClearFormData();
        }
        this.ClearCheck();
    }
}
